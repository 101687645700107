


#product_form > div > div {
    text-align: left;
    margin-bottom: 20px;
}
#product_form label{
    margin-right: 78px;
    font-size: 18px;
    font-family: sans-serif;
}
#product_form div > div:nth-child(1) >label{
    margin-right: 90px;
}
#product_form div > div:nth-child(3) >label{
    margin-right: 57px;
}
#product_form input[type="text"]{
    cursor: text;
    padding: 5px;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 6px;
    /* width: 20%; */
}
#product_form input[type="text"]:focus{
    outline: 1px solid rgb(22, 240, 240) !important;
}

#product_form select{
    width: 150px;
    cursor: pointer;
    padding: 5px;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 6px;
}

#product_form select:focus{
    outline: 1px solid rgb(22, 240, 240) !important;
}