
.header{
    margin-top: 50px;
    padding: 0px;
}
.header button{
    border-radius: 15px !important;
    margin: 5px;
    cursor: pointer !important;
    height: 70%;
    font-size: 17px !important;
    text-align: center !important;
}
.btns{
    justify-content: flex-end;
    padding-right: 0px;
}
.title{
    padding-left: 0px;
}
.ADD{
    
}
#add-product-btn{

}
#delete-product-btn{

}
@media (max-width: 450px){
    .btns{
        padding: 0px;
    }
    .header button{
        margin: 2px;
        border-radius: 15px !important;
        margin: 5px;
        height: 70%;
        font-size: 11px !important;
        text-transform: capitalize !important;
        text-align: center !important;
        vertical-align: middle !important;
    }

}

