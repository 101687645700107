
.product{
    border: 1px solid rgb(172, 172, 172);
    margin: 10px;
}

.check{
    margin: 5px;
}
.product  p{
    margin: 8px;
}
.delete-checkbox{
    cursor: pointer !important;
}